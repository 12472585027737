<template>
  <div class="list-medicines">
    <div class="border border-gray-400 p-3">
      <div class="flex justify-between items-center px-4 py-1 bg-primary">
        <div class="flex">
          <div v-if="isRtl" class="flex flex-col -mt-1">
            <i class="text-lime-300 fas fa-caret-up"></i>
            <i class="-mt-2 text-lime-300 fas fa-caret-down"></i>
          </div>
          <h3 class="text-white mr-5">{{ $t('list_medicines_title') }}</h3>
        </div>
        <div>
          <div
            @click="$emit('toggle')"
            v-if="isLtr"
            class="flex flex-col -mt-1 cursor-pointer"
          >
            <i class="text-lime-300 fas fa-caret-up"></i>
            <i class="-mt-2 text-lime-300 fas fa-caret-down"></i>
          </div>
          <i
            v-if="isRtl"
            @click="$emit('toggle')"
            class="cursor-pointer text-white text-xl fas fa-caret-up"
          ></i>
        </div>
      </div>

      <BaseTable
        class="text-right text-sm"
        header-class="bg-gray-50 text-gray-400 text-sm"
        :headers="headers"
        :items="items"
        theme-type="odd"
        dividable
      >
        <template #header:time="{ item }">
          <i class="ml-2 text-rose fas fa-caret-down"></i>
          {{ item.text }}
        </template>
        <template #header:medicine="{ item }">
          <i class="ml-2 text-rose fas fa-caret-down"></i>
          {{ item.text }}
        </template>
        <template #header:cycle="{ item }">
          <i class="ml-2 text-rose fas fa-caret-down"></i>
          {{ item.text }}
        </template>
        <template #header:amount="{ item }">
          <i class="ml-2 text-rose fas fa-caret-down"></i>
          {{ item.text }}
        </template>
      </BaseTable>
    </div>
  </div>
</template>
<script>
import BaseTable from '@/components/BaseTable'

export default {
  name: 'ListMedicines',
  components: { BaseTable },
  computed: {
    headers() {
      return [
        {
          key: 'time',
          text: this.$t('list_medicines_time'),
        },
        {
          key: 'medicine',
          text: this.$t('list_medicines_drugs'),
        },
        {
          key: 'cycle',
          text: this.$t('list_medicines_cycle'),
        },
        {
          key: 'amount',
          text: this.$t('list_medicines_amount'),
        },
        {
          key: 'description',
          text: this.$t('list_medicines_description'),
        },
      ]
    },
    items() {
      return [
        {
          time: this.$t('list_medicines_time_item'),
          medicine: 'LEVOXINE 100 MG',
          cycle: this.$t('list_medicines_cycle_item'),
          amount: this.$t('list_medicines_amount_item'),
          description: this.$t('list_medicines_description_item'),
        },
        {
          time: this.$t('list_medicines_time_item'),
          medicine: 'LOZATEN-H',
          cycle: this.$t('list_medicines_cycle_item'),
          amount: this.$t('list_medicines_amount_item'),
          description: this.$t('list_medicines_description_item2'),
        },
        {
          time: this.$t('list_medicines_time_item'),
          medicine: 'A.S.A 80',
          cycle: this.$t('list_medicines_cycle_item'),
          amount: this.$t('list_medicines_amount_item'),
          description: this.$t('list_medicines_description_item3'),
        },
        {
          time: this.$t('list_medicines_time_item'),
          medicine: 'FLOUXITINE 20',
          cycle: this.$t('list_medicines_cycle_item'),
          amount: this.$t('list_medicines_amount_item'),
          description: this.$t('list_medicines_description_item4'),
        },
      ]
    },
  },
  data() {
    return {
      show: false,
    }
  },
}
</script>

<style></style>
