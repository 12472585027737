<template>
  <div class="">
    <div class="m-5 bg-white">
      <div class="border border-gray-400">
        <div class="p-4 flex justify-between items-center">
          <h3
            :class="{ 'text-rose': isLtr, 'text-gray-400': isRtl }"
            class="font-bold"
          >
            {{ $t('title_repors') }}
            <span class="text-rose">{{ $t('medicin_title') }}</span>
          </h3>
          <BackFa :text="$t('back')" />
        </div>

        <BaseTable
          class="mb-6"
          header-class="bg-gray-50 text-gray-400 text-sm"
          :headers="headers"
          :items="items"
          theme-type="even"
        >
          <template #item:docter="{ item }">
            <div class="flex items-center">
              <div
                :class="{ 'ml-2': isRtl, 'mr-2': isLtr }"
                class="bg-gray-200 h-10 w-10 rounded-lg"
              ></div>
              <div class="font-extralight">
                <h3 class="text-base">{{ item.docter }}</h3>
                <h3 class="text-xs font-light text-gray-600">
                  {{ item.specialty }}
                </h3>
              </div>
            </div>
          </template>
          <template #item:document="{ item }">
            <img width="32" class="mx-auto" :src="item.document" alt="" />
          </template>
          <template #item:allergy="{ item }">
            <span
              class="text-white px-6 py-1 rounded-2xl shadow-lg"
              :class="item.class || 'bg-cyan-400'"
              >{{ item.allergy }}</span
            >
          </template>
          <template #item:drugs>
            <i
              class="text-base p-2 text-gray-400 fas fa-ellipsis-v cursor-pointer"
              @click="showListMedicines = !showListMedicines"
            ></i>
          </template>
        </BaseTable>
      </div>
    </div>
    <transition name="fade">
      <ListMedicines
        v-if="showListMedicines"
        class="bg-white mx-8"
        @toggle="showListMedicines = !showListMedicines"
      />
    </transition>
  </div>
</template>
<script>
import ListMedicines from '../components/ListMedicines.vue'
import BaseTable from '@/components/BaseTable'
import BackFa from '../components/back/BackFa.vue'

export default {
  components: { ListMedicines, BaseTable, BackFa },
  name: 'Medicines',

  computed: {
    headers() {
      return [
        {
          key: 'docter',
          text: `${this.$t('doctor_reports')}/${this.$t('specialty_reports')}`,
        },
        {
          key: 'date',
          text: this.$t('date_reports'),
        },
        {
          key: 'subject',
          text: this.$t('subject_reports2'),
        },
        {
          key: 'allergy',
          text: this.$t('allergy_reports'),
        },
        {
          key: 'drugs',
          text: this.$t('list_drugs'),
          align: 'center',
        },
      ]
    },
    items() {
      return [
        {
          date: this.convertDate('2021-1-1'),
          specialty: this.$t('medicin_specialty'),
          docter: this.$t('medicin_doctor'),
          subject: this.$t('medicin_subject'),
          allergy: this.$t('medicin_allergy'),
        },
        {
          date: this.convertDate('2021-1-2'),
          specialty: this.$t('medicin_specialty'),
          docter: this.$t('medicin_doctor'),
          subject: this.$t('medicin_subject'),
          allergy: this.$t('medicin_allergy2'),
          class: 'bg-rose',
        },
      ]
    },
  },
  data() {
    return {
      showListMedicines: false,
    }
  },
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
